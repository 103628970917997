import React from 'react';
import {Button, Card, Col, Form, Input, message, notification, Row, Typography} from 'antd';
import {useMutation} from "@tanstack/react-query";
import {SaveOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import axios from "../../axios";

const {Title} = Typography;

export const Profile = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const updatePassword = useMutation({
    mutationFn: (password) => {
      return axios.put(`/users/change-password`, password);
    },
    onSuccess: () => {
      notification.info({
        message: "Gelukt!",
        description: "Wachtwoord succevol gewijzigd :)",
        duration: 15
      })
      navigate("/");
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });

  // const handleSubmit = async (values) => {
  //   try {
  //     // Perform the password change logic here
  //     // For demonstration purposes, we're just logging the values
  //     console.log(values);
  //
  //     // Display success message
  //     message.success('Password changed successfully');
  //   } catch (error) {
  //     // Display error message
  //     message.error('Failed to change password');
  //   }
  // };
  //
  // const [mutate] = useMutation(handleSubmit);

  const onFinish = (values) => {
    // Validate that both passwords are the same
    if (values.password !== values.confirmPassword) {
      message.error('Passwords do not match');
      return;
    }

    updatePassword.mutate({
      "password": values.password
    });
  };

  return (
    <div>
      <Title level={1}>Profiel</Title>
      <Card>
        <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="password"
                label="Nieuw wachtwoord"
                rules={[
                  {
                    required: true,
                    message: 'Geef je nieuwe wachtwoord op',
                  },
                ]}
              >
                <Input.Password/>
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Bevestig wachtwoord"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Bevestig aub je nieuwe wachtwoord'
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Passwords do not match'));
                    },
                  }),
                ]}
              >
                <Input.Password/>
              </Form.Item>
            </Col>
          </Row>

          <Row style={{marginTop: "1rem"}}>
            <Col span={24}>
              <Button type={"primary"} icon={<SaveOutlined/>} htmlType={"submit"} style={{float: "right"}}>
                Opslaan
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

