import React from 'react';
import {useNavigate} from 'react-router-dom';

const WithNavigate = (WrappedComponent) => {
  const NavigateWrapper = (props) => {
    const navigate = useNavigate();
    return <WrappedComponent navigate={navigate} {...props} />;
  };

  return NavigateWrapper;
};

export default WithNavigate;
