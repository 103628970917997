import React, {useEffect, useState} from "react";
import {Alert, Button, Input, notification} from "antd";
import {gql, useLazyQuery} from "@apollo/client";
import {Spin} from "antd/es";
import axios from "../../axios";
import {useNavigate} from "react-router-dom";
import {useIsAdmin} from "../../hooks/useIsAdmin";

const {TextArea} = Input;

const GENERATE_AI_SHIPPING_ORDER = gql`
    query GetShippingOrder($rawText: String!) {
        shippingOrderAI(rawText: $rawText) {
            customer {
                name
            }
            orderReference
            loadingAddress {
                name
                street
                zipCode
                city
                country
            }
            loadingDate
            loadingTimeFrom
            loadingTimeTo
            loadingReference
            unloadingAddress {
                name
                street
                zipCode
                city
                country
            }
            unloadingDate
            unloadingTimeFrom
            unloadingTimeTo
            unloadingReference
            goods {
                description
                amount
                remark
                cubic_meters
                loading_meters
                height
                length
                type
            }
        }
    }
`;

const Home = () => {
  const [rawTextInput, setRawTextInput] = useState('');
  const [orderURL, setOrderURL] = useState(null);
  const [getAIGenerateShippingOrder, {loading, data}] = useLazyQuery(GENERATE_AI_SHIPPING_ORDER);
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (data) {
      const createShippingOrder = async () => {
        try {
          const response = await axios.post('/shippingOrders/ai', data);

          setOrderURL(response.data.id);
        } catch (error) {
          console.error('Error posting data:', error);
        }
      };

      createShippingOrder();
    }
  }, [data]);

  if (orderURL) {
    const args = {
      message: "🤖 De opdracht is succesvol aangemaakt!",
      description: "Vergeet niet de opdracht te checken.",
      duration: 15
    };
    notification.success(args);
    navigate(`/orders/${orderURL}`)
  }

  return (
    <>
      <Alert
        type="info"
        message="Beste gebruiker,"
        description={
          <>
            <p>
              Hoi! <br></br>
              Via Mathias kan je de AI eens proberen om opdrachten aan te maken.<br></br>
              Dit is een experimentele functie die eerst nog wat getest moet worden. <br></br>
              Als het goed gaat implementeren we hem verder.
            </p>
            <p>
              Groetjes,
            </p>
            <p>
              Patrick Dronk
            </p>
          </>
        }
        showIcon
        style={{marginBottom: "1rem"}}
      />

      {isAdmin && (
        <Alert
          type={"success"}
          description={
            <Spin spinning={loading}>
              <div>
                <p>
                  Hey Mathias, idee is nu om hier de text van de PDF in te doen (kopieer gewoon alle text uit de PDF).
                  Dat was voor mij nu ff makkelijker 😇
                  Als er opdrachten zijn die niet goed gaan of rare dingen doen, laat het me weten! En geef de
                  specifieke opdracht door. Dan kan ik er naar kijken.
                </p>
                <TextArea
                  rows={4}
                  value={rawTextInput}
                  onChange={(e) => setRawTextInput(e.target.value)}
                />
                <Button
                  style={{marginTop: '12px'}}
                  onClick={() => getAIGenerateShippingOrder({variables: {rawText: rawTextInput.trim()}})}
                >
                  ATMS-AI
                </Button>
              </div>
            </Spin>
          }
        >
        </Alert>
      )}
    </>
  );
};

export default Home;
