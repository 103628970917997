import React, {useEffect, useRef, useState} from "react";
import {
  CarOutlined,
  FileSyncOutlined,
  GiftOutlined,
  HourglassOutlined,
  PhoneOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";
import "./popup.css";
import {translateStatus} from "../../utils/statusHelper";


const Popup = ({record, visible, x, y, onClick}) => {
  const ref = useRef();
  const [adjustedY, setAdjustedY] = useState(y);
  const [observer, setObserver] = useState(null);

  console.log(x, adjustedY)
  useEffect(() => {
    setAdjustedY(y);
    if (visible) {
      if (observer !== null) {
        observer.disconnect();
      }
      const newObserver = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting) {
            setAdjustedY(y - entry.boundingClientRect.height);
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0
        }
      );
      newObserver.observe(ref.current);
      setObserver(newObserver);
    } else if (observer !== null) {
      observer.disconnect();
      setObserver(null);
    }
  }, [y, visible]);

  return (
    visible && (
      <ul
        ref={ref}
        className="popup"
        style={{position: "fixed", left: `${x}px`, top: `${adjustedY}px`}}
      >
        <li onClick={() => onClick({id: record.id, status: "1"})}>
          <HourglassOutlined/> {translateStatus("1")}
        </li>
        <li onClick={() => onClick({id: record.id, status: "2"})}>
          <CarOutlined/> {translateStatus("2")}
        </li>
        <li onClick={() => onClick({id: record.id, status: "3"})}>
          <GiftOutlined/> {translateStatus("3")}
        </li>
        <li onClick={() => onClick({id: record.id, status: "4"})}>
          <PhoneOutlined/> {translateStatus("4")}
        </li>
        <li onClick={() => onClick({id: record.id, status: "5"})}>
          <ClockCircleOutlined/> {translateStatus("5")}
        </li>
        {record.transporter.api_id === "1" && (
          <li onClick={() => onClick({id: record.id, status: "EDI"})}>
            <FileSyncOutlined/>
            Verstuur naar EDI koppeling
          </li>
        )}
      </ul>
    )
  );
};

export default Popup;
