import React, {useState} from "react";
import {AutoComplete, Form, Input} from "antd";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";

export const LoadAddressSelect = (props) => {
  const [searchTerm, setSearchTerm] = useState()
  const {isLoading, data} = useQuery(
    ['addressQuery', searchTerm],
    () => axios.get(`/address/search?searchTerm=${searchTerm}`),
    {
      enabled: !!searchTerm
    })

  const handleChange = (value, other, other1) => {
    //find the select address from the data.data
    const address = data.data.find((address) => address.name === value);
    props.form.setFieldsValue({
      "loadingAddress": {
        "name": address.name,
        "street": address.street,
        "zipCode": address.zipCode,
        "city": address.city,
        "country": address.country
      }
    });
  }

  return (
    <>
      <Form.Item label={"Laadlocatie"} name={["loadingAddress", "name"]}>
        <AutoComplete
          loading={isLoading}
          placeholder="Typ om te zoeken naar een eerder gebruikt adres of vul een nieuwe in"
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={setSearchTerm}
          onSelect={handleChange}
          options={(data?.data || []).map((d) => ({
            value: d.name,
            label: d.name,
          }))}
        />
      </Form.Item>
      <Form.Item label={"Straat"} name={["loadingAddress", "street"]}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Postcode"} name={["loadingAddress", "zipCode"]}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Plaats"} name={["loadingAddress", "city"]}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Land"} name={["loadingAddress", "country"]}>
        <Input/>
      </Form.Item>
    </>
  );
};