import React from "react";
import {Tag} from "antd";

export function translateStatus(status) {
  switch (status) {
    case "1":
      return "Order ontvangen";
    case "2":
      return "Geladen";
    case "3":
      return "Geleverd";
    case "4":
      return "Opgevraagd";
    case "5":
      return "Vertraagd";
    default:
  }
}

export function translateStatusWithTag(status) {
  switch (status) {
    case "1":
      return <Tag color="geekblue">Order ontvangen</Tag>;
    case "2":
      return <Tag color="blue">Geladen</Tag>;
    case "3":
      return <Tag color="green">Geleverd</Tag>;
    case "4":
      return <Tag color="yellow">Opgevraagd</Tag>;
    case "5":
      return <Tag color="red">Vertraagd</Tag>;
    default:
  }
}
