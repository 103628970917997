import React, {useState} from 'react'
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "../../axios";
import {Button, Col, message, notification, Row, Table, Typography} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {useIsAdmin} from "../../hooks/useIsAdmin";

const {Title} = Typography;

export const UsersList = () => {
  const isAdmin = useIsAdmin();

  const [page, setPage] = useState({
    results: 10,
    page: 1,
    sortField: "id",
    sortOrder: "DESC"
  });
  const deleteUser = useMutation({
    mutationFn: (userId) => {
      return axios.delete(`/users/${userId}`);
    },
    onSuccess: () => {
      message.info("Gebruiker is verwijderd")
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });
  const {isLoading, data} = useQuery({
    queryKey: ["users", deleteUser, page],
    queryFn: () =>
      axios
        .get(`/users?results=${page.results}&page=${page.page}&sortField=${page.sortField}&sortOrder=${page.sortOrder}`)
        .then((res) => res.data),
    keepPreviousData: true
  });
  const navigate = useNavigate();

  const columns = [
    {
      title: "Naam",
      dataIndex: "username",
      key: "name",
      render: (text, {id}) =>
        (<Link to={`/users/${id}`} disabled>{text}</Link>)
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Acties",
      dataIndex: "operation",
      key: "operation",
      render: (text, {id}) => (
        <Button
          type="danger"
          shape="circle"
          icon={<DeleteOutlined/>}
          onClick={() => deleteUser.mutate(id)}
        />
      )
    }
  ]
  const onTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === "ascending" ? "ASC" : "DESC";
    const newPage = {
      ...page,
      page: pagination.current,
      sortOrder
    };
    setPage(newPage);
  };

  if (!isAdmin) {
    return <div>Geen toegang</div>
  }

  return (
    <div>
      <Title level={1}>Gebruikers</Title>
      <Row justify={"end"}>
        <Col>
          <Button icon={<PlusCircleOutlined/>} onClick={() => navigate("/users/create")} size={"large"}>
            Voeg toe
          </Button>
        </Col>
      </Row>
      <Row style={{marginTop: "1rem"}}>
        <Col span={24}>
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={data?.data}
            loading={isLoading}
            pagination={{
              current: data?.page,
              total: data?.total
            }}
            onChange={onTableChange}
          />
        </Col>
      </Row>
    </div>
  )
}