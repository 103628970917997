import React, {useEffect} from "react";
import {Button, Card, Checkbox, Col, Divider, Form, Input, notification, Row, Select, Typography} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";

const FormItem = Form.Item;
const Option = Select.Option;
const {TextArea} = Input;
const {Title} = Typography;

const Transporter = (props) => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [form] = Form.useForm();

  const createTransporter = useMutation({
    mutationFn: (transporter) => {
      return axios.post(`/transporters`, transporter);
    },
    onSuccess: () => {
      navigate("/transporters");
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });

  const transporter = useQuery({
    queryKey: ["transporter", id],
    queryFn: () => axios.get(`/transporters/${id}`)
      .then((res) => res.data),
    enabled: !!id
  });

  useEffect(() => {
    if (!transporter.isLoading && transporter.data) {
      form.setFieldsValue(transporter.data);
    }
  }, [transporter.isLoading, transporter.data, form]);

  const onFinish = (form) => {
    createTransporter.mutate(form);
  };

  return (
    <div>
      <Title level={1}>{id ? "Bewerk Transporteur" : "Nieuwe Transporteur"}</Title>
      <Card>
        <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Title level={3}>Algemene informatie</Title>
              {id && (
                <>
                  <FormItem label={"Code"} name={"transporterCode"}>
                    <Input disabled/>
                  </FormItem>
                  <FormItem hidden label={"id"} name={"id"}>
                    <Input/>
                  </FormItem>
                </>
              )}
              <FormItem label={"Naam"} name={"name"}>
                <Input/>
              </FormItem>
              <FormItem label={"Relatie"} name={"relation"}>
                <Select>
                  <Option value="Bedrijf">Bedrijf</Option>
                  <Option value="Particulier">Particulier</Option>
                </Select>
              </FormItem>
              <FormItem label={"KVK nummer"} name={"kvkNr"}>
                <Input/>
              </FormItem>
              <FormItem label={"Contact persoon"} name={"contactPerson"}>
                <Input/>
              </FormItem>
              <FormItem label={"Aanhef"} name={"salutation"}>
                <Input/>
              </FormItem>
              <FormItem label={"Opmerkingen"} name={"remarks"}>
                <TextArea autosize={{minRows: 2, maxRows: 6}}/>
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Title level={3}>Adresgegevens</Title>
              <FormItem label={"Straat"} name={"street"}>
                <Input/>
              </FormItem>
              <FormItem label={"Postcode"} name={"zipCode"}>
                <Input/>
              </FormItem>
              <FormItem label={"Stad"} name={"city"}>
                <Input/>
              </FormItem>
              <FormItem label={"Land"} name={"country"}>
                <Input/>
              </FormItem>
              <FormItem label={"Postbus"} name={"postBox"}>
                <Input/>
              </FormItem>
              <FormItem label={"Koppeling"} name={"api_id"}>
                <Select>
                  <Option value={"0"}>Geen</Option>
                  <Option value="1">Verploegen</Option>
                </Select>
              </FormItem>
            </Col>
            <Divider/>
            <Col xs={24} lg={12}>
              <Title level={3}>Contactgegevens</Title>
              <FormItem label={"Telefoon"} name={"phoneNumber"}>
                <Input/>
              </FormItem>
              <FormItem label={"Telefoon factuur"} name={"phoneNumberInvoice"}>
                <Input/>
              </FormItem>
              <FormItem label={"Email"} name={"email"}>
                <Input/>
              </FormItem>
              <FormItem label={"Email nieuwsbrief"} name={"emailNewsletter"}>
                <Input/>
              </FormItem>
              <FormItem label={"Email factuur"} name={"emailInvoice"}>
                <Input/>
              </FormItem>
              <FormItem label={"Email herinnering"} name={"emailReminders"}>
                <Input/>
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <Title level={3}>Betaalgegevens</Title>
              <FormItem label={"Rekeningnummer"} name={"bankAccount"}>
                <Input/>
              </FormItem>
              <FormItem label={"Gironummer"} name={"giroAccount"}>
                <Input/>
              </FormItem>
              <FormItem label={"IBAN"} name={"iban"}>
                <Input/>
              </FormItem>
              <FormItem label={"BIC"} name={"bic"}>
                <Input/>
              </FormItem>
              <FormItem label={"BTW nummer"} name={"btwNr"}>
                <Input/>
              </FormItem>
              <FormItem label={"Betalingstermijn"} name={"prompt"}>
                <Input/>
              </FormItem>
              <FormItem label={"POD facturatie"} name={"podInvoicing"} valuePropName="checked">
                <Checkbox/>
              </FormItem>
            </Col>
          </Row>
          <Row style={{marginTop: "1rem"}}>
            <Col span={24}>
              <Button type={"primary"} icon={<SaveOutlined/>} htmlType={"submit"} style={{float: "right"}}>
                Opslaan
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default Transporter;
