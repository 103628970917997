import React from "react";
import {Form, Input} from "antd";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {useParams} from "react-router-dom";

export const TransporterReferenceInput = (props) => {
  const transporterReference = props.form.getFieldValue("transporterReference");
  const {id} = useParams();

  const nextId = useQuery({
    queryKey: ["nextId"],
    queryFn: () => axios.get(`/shippingOrders/getNextId`).then((res) => res.data),
  });

  if (id !== undefined) {
    return (
      <Form.Item label={"Dossiernummer"} name={"transporterReference"}>
        <Input disabled value={transporterReference}/>
      </Form.Item>
    );
  }

  return (
    <Form.Item label={"Dossiernummer"} name={"transporterReference"}>
      <div hidden> {nextId.data}</div>
      <Input disabled value={nextId.data}/>
    </Form.Item>
  );
};
