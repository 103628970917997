import {useEffect, useState} from "react";

const useCostsAndYields = (initialCosts = 0, initialYields = 0) => {
  const [costsAndYields, setCostsAndYields] = useState({costs: initialCosts, yields: initialYields});

  useEffect(() => {
    setCostsAndYields({costs: initialCosts, yields: initialYields});
  }, [initialCosts, initialYields]);

  const handleCostsChange = (e) => {
    setCostsAndYields({...costsAndYields, costs: e.target.value});
  };

  const handleYieldsChange = (e) => {
    setCostsAndYields({...costsAndYields, yields: e.target.value});
  };

  const margin = (Number(costsAndYields.yields) - Number(costsAndYields.costs)).toFixed(2);

  return {
    margin,
    handleCostsChange,
    handleYieldsChange,
  };
};

export default useCostsAndYields;
