import React from "react";
import {Checkbox, Form, Input} from "antd";

export const UnloadingAnnouncement = () => {
  return (
    <>
      <Form.Item label={"Aanmelden"} name={"unloadingAnnouncement"} valuePropName="checked">
        <Checkbox/>
      </Form.Item>
      <Form.Item label={"Aanmeld telefoonnummer"} name={"unloadingAnnouncementPhoneNumber"}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Aanmeld email"} name={"unloadingAnnouncementEmail"}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Aanmeld contactpersoon"} name={"unloadingAnnouncementContact"}>
        <Input/>
      </Form.Item>
    </>
  );
};
