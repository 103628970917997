import React, {useState} from "react";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Button, Input, Row, Table, Typography} from "antd";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "../../axios";
import {useNavigate} from "react-router-dom";

const Search = Input.Search;
const {Title} = Typography;

const TransportersList = () => {
  const columns = [
    {
      title: "Naam",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text, {id}) => {
        return (
          <button className="link-button" onClick={() => navigate(`/transporters/${id}`)}>
            {text}
          </button>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true
    },
    {
      title: "Straat",
      dataIndex: "street",
      key: "street"
    },
    {
      title: "Postcode",
      dataIndex: "zipCode",
      key: "zipCode"
    },
    {
      title: "Stad",
      dataIndex: "city",
      key: "city"
    },
    {
      title: "Land",
      dataIndex: "country",
      key: "country"
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: (text, {id}) => (
        <div>
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined/>}
            onClick={() => this.props.removeTransporter(id)}
          />
        </div>
      )
    }
  ];
  const [page, setPage] = useState({
    results: 10,
    page: 1,
    sortField: "id",
    sortOrder: "DESC"
  });
  const [searchTerm, setSearchTerm] = useState("");
  const deleteTransporter = useMutation({
    mutationFn: (id) => {
      return axios.delete(`/transporters/${id}`);
    }
  });
  const {isLoading, data} = useQuery({
    queryKey: ["transporters", searchTerm, page, deleteTransporter],
    queryFn: () =>
      axios
        .get(`/transporters?results=${page.results}&page=${page.page}&sortField=${page.sortField}&sortOrder=${page.sortOrder}&searchTerm=${searchTerm}`)
        .then((res) => res.data),
    keepPreviousData: true
  });
  const navigate = useNavigate();

  const onTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === "ascending" ? "ASC" : "DESC";
    const newPage = {
      ...page,
      page: pagination.current,
      sortOrder
    };
    setPage(newPage);
  };

  return (
    <div>
      <Title level={1}>Transporteurs</Title>
      <Row justify={"end"} gutter={16}>
        <Search
          placeholder="Zoek"
          size={"large"}
          onSearch={setSearchTerm}
          style={{width: 200, marginRight: "16px"}}
        />
        <Button icon={<PlusCircleOutlined/>} onClick={() => {
          navigate("/transporters/create");
        }} size={"large"}>
          Voeg toe
        </Button>
      </Row>
      <Row style={{marginTop: "1rem"}}>
        <Table
          columns={columns}
          dataSource={data?.data}
          loading={isLoading}
          rowKey={"id"}
          pagination={{
            current: Number(page.page),
            total: Number(page.total)
          }}
          onChange={onTableChange}
        />
      </Row>
    </div>
  );
};

export default TransportersList;
