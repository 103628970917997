import React, {Component} from "react";
import {Checkbox, Col, Row, Select} from "antd";
import axios from "axios";
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from "recharts";

const address = process.env.REACT_APP_API_URL;

const Option = Select.Option;

class FinanceByTransporter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: [],
      selectedFiltering: {
        shippingOrderCount: true,
        costs: true,
        yields: true,
        marge: true
      }
    };
  }

  componentDidMount() {
    this.props.getTransporters();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selectedTransporter &&
      prevProps.selectedTransporter !== this.props.selectedTransporter
    ) {
      const {data} = await axios.get(
        `${address}/finance/transporter/${this.props.selectedTransporter}`
      );
      this.setState({data});
    }
  }

  renderOptions() {
    const {transporters} = this.props;
    return transporters.map(transporter => {
      return (
        <Option key={transporter.id} value={transporter.id}>
          {transporter?.name}
        </Option>
      );
    });
  }

  onChange = e => {
    this.setState({
      selectedFiltering: {
        ...this.state.selectedFiltering,
        [e.target.value]: e.target.checked
      }
    });
  };

  render() {
    const {selectedFiltering} = this.state;
    return (
      <div>
        <Row justify={"end"}>
          <Select
            showSearch
            size={"large"}
            style={{width: 300}}
            placeholder="Selecteer een klant"
            optionFilterProp="children"
            onChange={this.props.setSelectedTransporter}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.renderOptions()}
          </Select>
        </Row>
        {this.state.data.length > 0 && (
          <Row>
            <Col span={4}>
              <Row>
                <Checkbox
                  value="shippingOrderCount"
                  checked={selectedFiltering.shippingOrderCount}
                  onChange={this.onChange}
                >
                  Aantal opdrachten
                </Checkbox>
              </Row>
              <Row>
                <Checkbox
                  value="costs"
                  checked={selectedFiltering.costs}
                  onChange={this.onChange}
                >
                  Kosten
                </Checkbox>
              </Row>
              <Row>
                <Checkbox
                  value="yields"
                  checked={selectedFiltering.yields}
                  onChange={this.onChange}
                >
                  Opbrengsten
                </Checkbox>
              </Row>
              <Row>
                <Checkbox
                  value="marge"
                  checked={selectedFiltering.marge}
                  onChange={this.onChange}
                >
                  Marge
                </Checkbox>
              </Row>
            </Col>
            <Col span={20}>
              <BarChart width={730} height={250} data={this.state.data}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                {selectedFiltering.shippingOrderCount && (
                  <Bar
                    dataKey="shippingOrderCount"
                    name={"Aantal"}
                    fill="#52c41a"
                  />
                )}
                {selectedFiltering.costs && (
                  <Bar dataKey="costs" name={"Kosten"} fill="#13c2c2"/>
                )}
                {selectedFiltering.yields && (
                  <Bar dataKey="yields" name={"Opbrengsten"} fill="#1890ff"/>
                )}
                {selectedFiltering.marge && (
                  <Bar dataKey="marge" name={"marge"} fill="#2f54eb"/>
                )}
              </BarChart>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default FinanceByTransporter;
