import React from "react";
import {Checkbox, Form, Input} from "antd";

export const LoadingAnnouncement = () => {
  return (
    <>
      <Form.Item label={"Aanmelden"} name={"loadingAnnouncement"} valuePropName="checked">
        <Checkbox/>
      </Form.Item>
      <Form.Item label={"Aanmeld telefoonnummer"} name={"loadingAnnouncementPhoneNumber"}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Aanmeld email"} name={"loadingAnnouncementEmail"}>
        <Input/>
      </Form.Item>
      <Form.Item label={"Aanmeld contactpersoon"} name={"loadingAnnouncementContact"}>
        <Input/>
      </Form.Item>
    </>
  );
};
