import React, {useCallback, useState} from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EuroOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
  MailOutlined,
  PlusCircleOutlined,
  ToolOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  notification,
  Row,
  Table,
  Tag,
  Typography,
  Upload,
  Badge
} from "antd";
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios from "../../axios";
import "./ShippingOrder.css";

const address = process.env.REACT_APP_API_URL;
const {Search} = Input;
const {Title} = Typography;

function ShippingOrdersList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  //State hooks
  const [page, setPage] = useState({results: 10, page: 1, sortField: "id", sortOrder: "DESC"});
  const [searchTerm, setSearchTerm] = useState("");

  //Query hooks
  const {isLoading, data} = useQuery(["shippingOrders", searchTerm, page], () =>
    axios
      .get(`/shippingOrders?results=${page.results}&page=${page.page}&sortField=${page.sortField}&sortOrder=${page.sortOrder}&searchTerm=${searchTerm}`)
      .then((res) => res.data)
  );

  // Mutation hooks
  const togglePaid = useMutation((id) =>
      axios.put(`/shippingOrders/paid/${id}`), {
      onSuccess: () => {
        queryClient.invalidateQueries(["shippingOrders", searchTerm, page]);
      }
    }
  );
  const generatePDF = useMutation(({id, transporterReference}) =>
    axios({
      url: `/shippingOrders/${id}/pdf`,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `shippingOrder_${transporterReference}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  );
  const emailPDF = useMutation(({id, transporterReference}) =>
    axios.get(`/shippingOrders/${id}/mailPDF`, {transporterReference})
  );
  const generateXML = useMutation(({id, transporterReference}) =>
    axios({
      url: `/shippingOrders/${id}/xml`,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `transportopdracht_${transporterReference}.xml`);
      document.body.appendChild(link);
      link.click();
    })
  );
  const sendXML = useMutation(({id, transporterReference}) =>
      axios.post(`/shippingOrders/${id}/api`),
    {
      onSuccess: () => {
        notification["success"]({
          message: `Opdracht is verstuurd`
        });
      }
    }
  );
  const removeShippingOrder = useMutation((id) =>
      axios.delete(`/shippingOrders/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["shippingOrders", searchTerm, page]);
      }
    }
  );

  // Render functions
  const renderDate = useCallback((date) => {
    return moment(date).format("DD-MM-YYYY");
  }, []);

  const renderEmailStatus = useCallback((status) => {
    if (status === 1) {
      return <CheckOutlined style={{color: "green"}}/>;
    } else {
      return <CloseOutlined style={{color: "red"}}/>;
    }
  }, []);

  const renderPaid = useCallback((status) => {
    if (!!status === true) {
      return <Tag color="#87d068">Betaald</Tag>;
    } else {
      return <Tag color="#f50">Niet betaald</Tag>;
    }
  }, []);

  const columns = [
    {
      title: "Dossiernummer",
      dataIndex: "transporterReference",
      key: "transporterReference",
      render: (text, {id}) => <Link to={`/orders/${id}`}>{text}</Link>
    },
    {
      title: "Laaddatum",
      dataIndex: "loadingDate",
      key: "loadingTime",
      render: renderDate
    },
    {
      title: "Losdatum",
      dataIndex: "unloadingDate",
      key: "unloadingTime",
      render: renderDate
    },
    {
      title: "Klant",
      dataIndex: ["customer", "name"],
      key: "customer.name",
      ellipsis: true
    },
    {
      title: "Laadadres",
      dataIndex: ["loadingAddress", "name"],
      key: "loadingAddress.name"
    },
    {
      title: "Laadplaats",
      dataIndex: ["loadingAddress", "city"],
      key: "loadingAddress.city"
    },
    {
      title: "Losadres",
      dataIndex: ["unloadingAddress", "name"],
      key: "unloadingAddress.name"
    },
    {
      title: "Losplaats",
      dataIndex: ["unloadingAddress", "city"],
      key: "unloadingAddress.city"
    },
    {
      title: "Betaald",
      dataIndex: "paid",
      key: "paid",
      render: renderPaid
    },
    {
      title: "Email",
      dataIndex: "isEmailSent",
      key: "isEmailSent",
      render: renderEmailStatus
    },
    {
      title: "Acties",
      dataIndex: "operation",
      key: "operation",
      render: (text, shippingOrder) => {
        const {id, transporterReference, transporter, meta_data} = shippingOrder;
        const menu = (
          <Menu>
            <Menu.Item key="0" onClick={() => togglePaid.mutate(id)}>
              <EuroOutlined/> Markeer betaald
            </Menu.Item>
            <Menu.Item key="1" onClick={() => generatePDF.mutate({id, transporterReference})}>
              <FilePdfOutlined/> PDF genereren
            </Menu.Item>
            <Menu.Item key="2" onClick={() => emailPDF.mutate({id, transporterReference})}>
              <MailOutlined/> Mail PDF naar transporteur
            </Menu.Item>
            <Menu.Item key="3" onClick={() => generateXML.mutate({id, transporterReference})}>
              <FileOutlined/> Genereer XML
            </Menu.Item>
            {Number(transporter?.api_id) === 1 && (
              <Menu.Item key="5" onClick={() => sendXML.mutate({id})}>
                <FileSyncOutlined/> Verstuur naar EDI koppeling
              </Menu.Item>
            )}
            <Menu.Item key="4" onClick={() => removeShippingOrder.mutate(id)}>
              <DeleteOutlined style={{color: "red"}}/> Verwijderen
            </Menu.Item>
          </Menu>
        );

        const serializedData = JSON.parse(meta_data);

        return (
          serializedData?.fromApi ? (
            <Badge.Ribbon text="API">
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button type="default" shape="circle" icon={<ToolOutlined/>} style={{marginRight: 10}}/>
              </Dropdown>
            </Badge.Ribbon>
          ) : (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="default" shape="circle" icon={<ToolOutlined/>} style={{marginRight: 10}}/>
            </Dropdown>
          )
        );
      }
    }
  ];

  const handleSearch = useCallback(
    (value) => {
      setSearchTerm(value);
    },
    []
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      if (pagination.current !== Number(data?.page)) {
        const sortOrder = sorter.order === "ascending" ? "ASC" : "DESC";
        const sortField = sorter.field ? sorter.field : "id";
        setPage({results: 10, page: pagination.current, sortField, sortOrder});
      }
    },
    //eslint-disable-next-line
    [data?.page]
  );

  return (
    <div>
      <Title level={1}>Transport opdrachten</Title>
      <Row justify={"end"}>
        <div style={{marginRight: "16px"}}>
          <Upload
            name={"file"}
            action={`${address}/shippingOrders/import`}
            onChange={info => {
              if (info.file.status === "done") {
                message.success(
                  `${info.file.name} file uploaded successfully`
                );
              } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <Button size={"large"} icon={<PlusCircleOutlined/>}>
              Importeren
            </Button>
          </Upload>
        </div>
        <Search
          placeholder="Zoek"
          size={"large"}
          onSearch={handleSearch}
          style={{width: 200, marginRight: "16px"}}
        />
        <Button
          icon={<PlusCircleOutlined/>}
          onClick={() => {
            navigate("/orders/create");
          }}
          size={"large"}
        >
          Voeg toe
        </Button>
      </Row>
      <Row style={{marginTop: "1rem"}}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data?.data}
            rowKey={"id"}
            pagination={{
              current: Number(data?.page),
              total: Number(data?.total)
            }}
            onChange={handleTableChange}
            loading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ShippingOrdersList;
