import React from "react";
import {Form, Select} from "antd";

const Option = Select.Option;

const offices = [
  {id: "1", name: "@ded Logistics BV"},
  {id: "2", name: "@ded Logistics Warehousing BV"},
];

export const OfficeSelect = () => {
  return (
    <Form.Item label={"Filiaal"} name={"officeId"} required>
      <Select>
        {offices.map((office) => (
          <Option key={office.id} value={office.id}>{office.name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};
