import React from "react";
import {Button, Col, Form, Input, notification, Row} from "antd";
import useAxios from "axios-hooks";
import {SaveOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const FormItem = Form.Item;
const {TextArea} = Input;

const address = process.env.REACT_APP_API_URL;

function Address(props) {
  const id = props.match.params.id;
  const navigate = useNavigate();
  const [{data}] = useAxios(`${address}/address/${id}`);
  const [{loading: putLoading}, executePut] = useAxios(
    {
      url: `${address}/address/${id}`,
      method: "PUT"
    },
    {manual: true}
  );
  const [form] = Form.useForm();

  const onFinish = async values => {
    await executePut({
      data: {
        ...values
      }
    });

    notification["success"]({
      message: `Opgeslagen`
    });

    navigate("/address");
  };

  return (
    <div>
      <h1>Adres</h1>
      {data && (
        <Form form={form} onFinish={onFinish} initialValues={data}>
          <Row>
            <Col xs={24} lg={12}>
              <FormItem {...formItemLayout} name={"name"} label={"Locatienaam"}>
                <Input name={"name"} value={data.name} disabled/>
              </FormItem>
              <FormItem
                {...formItemLayout}
                name={"instruction"}
                label={"Standaard instructie"}
              >
                <TextArea id={"instruction"}/>
              </FormItem>
            </Col>
          </Row>
          <Row align={"center"} justify={"end"} style={{marginTop: "1rem"}}>
            <Col span={3}>
              <Button
                loading={putLoading}
                style={{float: "right"}}
                type={"primary"}
                icon={<SaveOutlined/>}
                htmlType={"submit"}
              >
                Opslaan
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12}
  }
};

export default Address;
