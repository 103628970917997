import React from "react";
import {Button, Col, Form, Input, InputNumber, Row, Select, Space} from "antd";
import {DeleteOutlined} from "@ant-design/icons";

const {Option} = Select;

const Goods = ({form}) => {
  const handleAddGood = () => {
    const currentGoods = form.getFieldValue("goods") || []; // Get the goods array, or an empty array if it's not set

    form.setFieldsValue({
      goods: [
        ...currentGoods,
        {
          amount: 1,
          type: "",
          name: "",
          weight: null,
          ldm: "",
          length: null,
          width: null,
          height: null,
          cbm: "",
          remark: ""
        }
      ]
    });
  };

  const handleRemoveGood = (index) => {
    const goods = form.getFieldValue("goods");
    goods.splice(index, 1);
    form.setFieldsValue({goods});
  };

  return (
    <>
      <Row gutter={[8, 8]} justify="start">
        <Col span={2}><strong>Aantal</strong></Col>
        <Col span={2}><strong>Soort</strong></Col>
        <Col span={3}><strong>Naam</strong></Col>
        <Col span={2}><strong>Gewicht</strong></Col>
        <Col span={2}><strong>LDM</strong></Col>
        <Col span={2}><strong>Lengte</strong></Col>
        <Col span={2}><strong>Breedte</strong></Col>
        <Col span={2}><strong>Hoogte</strong></Col>
        <Col span={2}><strong>CBM</strong></Col>
        <Col span={4}><strong>Opmerking</strong></Col>
        <Col span={1}></Col>
      </Row>
      <Form.List name="goods">
        {(fields, {add, remove}) => (
          <>
            {fields.map((field, index) => (
              <Row key={field.key} gutter={[4, 4]} justify="start" style={{marginBottom: "2px"}}>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "amount"]} wrapperCol={24}>
                    <InputNumber min={1} style={{width: "100%"}}/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "type"]} wrapperCol={24}>
                    <Select>
                      <Option value="blok">Blok</Option>
                      <Option value="cll">CLL</Option>
                      <Option value="euro">Euro</Option>
                      <Option value="ewp">EWP</Option>
                      <Option value="ftl">FTL</Option>
                      <Option value="load">Load</Option>
                      <Option value="bundel">Bundel</Option>
                      <Option value="ds">DS</Option>
                      <Option value="div">Div</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item {...field} name={[field.name, "name"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "weight"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "ldm"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "length"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "width"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "height"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} name={[field.name, "cbm"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item {...field} name={[field.name, "remark"]} wrapperCol={24}>
                    <Input/>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Space>
                    <Button
                      icon={<DeleteOutlined/>}
                      danger
                      onClick={() => handleRemoveGood(index)}
                    />
                  </Space>
                </Col>
              </Row>
            ))}
            <Row justify="end">
              <Col span={2}>
                <Button type="primary" onClick={handleAddGood}>
                  Toevoegen
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  );
};

export default Goods;
