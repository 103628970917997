import React, {useState} from "react";
import {Skeleton, Tabs} from "antd";
import {CalendarOutlined} from "@ant-design/icons";
import OrderOverview from "./OrderOverview";
import axios from "axios";
import {useMutation, useQuery} from "@tanstack/react-query";
import moment from "moment";

const fetchShippingOrders = async (key) => {
  const dateString = key.queryKey[1].format("YYYY-MM-DD");
  const {data} = await axios.get(
    `/shippingOrders/byDate?date=${dateString}`
  );
  return data;
};
const fetchTransporters = async () => {
  const {data} = await axios.get(
    `/transporters/all`
  );
  return data;
};

export const OverviewContainer = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedTransporter, setSelectedTransporter] = useState();
  const {data: transporters, isLoading: transportersLoading} = useQuery(['transporters'], fetchTransporters);
  const updateStatus = useMutation({
    mutationFn: ({id, status}) => {
      return axios.put(`/shippingOrders/updateStatus/${id}`, {
        status
      })
    }
  });
  const {
    data: shippingOrders,
    isLoading: shippingOrdersLoading
  } = useQuery(['shippingOrders', selectedDate, updateStatus], fetchShippingOrders);

  const loading = (shippingOrdersLoading || transportersLoading)

  const tabs = [
    {
      key: '1',
      label: <span><CalendarOutlined/> Opdrachtenoverzicht Laden</span>,
      children: loading ? <Skeleton/> :
        <OrderOverview
          type={"load"}
          loading={loading}
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
          shippingOrders={shippingOrders}
          transporters={transporters}
          selectedTransporter={selectedTransporter}
          setSelectedTransporter={setSelectedTransporter}
          updateStatus={updateStatus.mutate}
        />
    },
    {
      key: '2',
      label: <span><CalendarOutlined/>Oprachtenoverzicht Leveren</span>,
      children: loading ? <Skeleton/> :
        <OrderOverview
          type={"unload"}
          loading={loading}
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
          shippingOrders={shippingOrders}
          transporters={transporters}
          selectedTransporter={selectedTransporter}
          setSelectedTransporter={setSelectedTransporter}
          updateStatus={updateStatus.mutate}
        />,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={setActiveTab} items={tabs}>
    </Tabs>
  );
}
