import React, {Component} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Main from "./views/main/Main";
import Login from "./views/auth/login/Login";
import RequireAuth from "./views/auth/PrivateRoute";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://ai.a-tms.nl/graphql',
  cache: new InMemoryCache(),
});
const queryClient = new QueryClient();

class App extends Component {
  render() {
    return (
      <Router>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={client}>
            <Routes>
              <Route path={"/login"} element={<Login/>}/>
              <Route path="/*" element={
                <RequireAuth redirectTo="/login">
                  <Main/>
                </RequireAuth>
              }
              />
            </Routes>
          </ApolloProvider>
        </QueryClientProvider>
      </Router>
    );
  }
}

export default App;
