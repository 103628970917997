import {useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';

export const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      setIsAdmin(false);
    } else {
      const decodedToken = jwtDecode(token);
      setIsAdmin(decodedToken.data?.role === 'Administrator');
    }
  }, []); // only run this effect once, when the component mounts

  return isAdmin;
};
