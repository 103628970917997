import React from "react";
import {AutoComplete, Form, Select} from "antd";

const Option = AutoComplete.Option;

const INCOOptions = ["EXW", "FCA", "FOB", "CPT", "CIF", "CIP", "DAP", "DDP"];

export const IncoSelect = () => {
  return (
    <Form.Item label={"INCO terms"} name={"INCO"}>
      <Select showSearch>
        {INCOOptions.map(item => {
          return (
            <Option key={item} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};