import axios from "axios";
import {dispatch} from "@rematch/core";
import {notification} from "antd";
import moment from "moment";

const address = process.env.REACT_APP_API_URL;

export const auth = {
  state: {
    authenticated: false,
    authFailed: false
  },
  reducers: {
    setAuth(state, authenticated) {
      return {...state, authenticated};
    },
    setAuthFailed(state) {
      return {...state, authFailed: true};
    }
  },
  effects: {
    async login(payload, rootState) {
      try {
        const {data} = await axios.post(`${address}/login`, {
          email: payload.email,
          password: payload.password
        });
        axios.defaults.headers.common[
          "Authorization"
          ] = `Bearer ${data.token.token}`;
        sessionStorage.setItem("token", data.token.token);
        this.setAuth(true);
      } catch (e) {
        this.setAuth(false);
        this.setAuthFailed(true);
        notification["error"]({
          message: "Authenticatie mislukt",
          description: "Controleer je gebruikersnaam & wachtwoord"
        });
      }
    }
  }
};

export const customer = {
  state: {
    customer: {}
  },
  reducers: {
    onChange(state, payload) {
      return {
        ...state,
        customer: {
          ...state.customer,
          [payload.target.id]: payload.target.value
        }
      };
    },
    onCheckboxChange(state, payload) {
      return {
        ...state,
        customer: {
          ...state.customer,
          [payload.target.id]: payload.target.checked
        }
      };
    },
    onRelationChange(state, payload) {
      return {...state, customer: {...state.customer, relation: payload}};
    },
    resetCustomer(state) {
      return {...state, customer: {}};
    },
    setCurrentCustomer(state, customer) {
      return {...state, customer};
    }
  },
  effects: {
    async addCustomer(customer, rootState, cb) {
      try {
        const {data} = await axios.post(`${address}/customers`, customer);
        dispatch({type: "customers/addCustomer", payload: data});

        if (customer.id) {
          notification["success"]({
            message: `Klant is succesvol bijgewerkt`
          });
        } else {
          notification["success"]({
            message: `Klant is succesvol toegevoegd`
          });
        }
        this.resetCustomer();
        cb(data.id);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async updateCustomer(customer, rootState, cb) {
      try {
        await axios.put(`${address}/customers/${customer.id}`, customer);

        notification["success"]({
          message: `Klant is succesvol bijgewerkt`
        });
        this.resetCustomer();
        cb();
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async getCustomerById(id) {
      const {data} = await axios.get(`${address}/customers/${id}`);
      this.setCurrentCustomer(data);
    }
  }
};

export const transporter = {
  state: {
    transporter: {}
  },
  reducers: {
    onChange(state, payload) {
      return {
        ...state,
        transporter: {
          ...state.transporter,
          [payload.target.id]: payload.target.value
        }
      };
    },
    onCheckboxChange(state, payload) {
      return {
        ...state,
        transporter: {
          ...state.transporter,
          [payload.target.id]: payload.target.checked
        }
      };
    },
    onRelationChange(state, payload) {
      return {
        ...state,
        transporter: {...state.transporter, relation: payload}
      };
    },
    resetTransporter(state) {
      return {...state, transporter: {}};
    },
    setCurrentTransporter(state, transporter) {
      return {...state, transporter};
    },
    onSelectChange(state, api_id) {
      return {
        ...state,
        transporter: {
          ...state.transporter,
          api_id
        }
      };
    }
  },
  effects: {
    async addTransporter(transporter, rootState, cb) {
      try {
        const {data} = await axios.post(
          `${address}/transporters`,
          transporter
        );
        dispatch({type: "transporters/addTransporter", payload: data});

        if (transporter.id) {
          notification["success"]({
            message: `Transporteur is succesvol bijgewerkt`
          });
        } else {
          notification["success"]({
            message: `Transporteur is succesvol toegevoegd`
          });
        }
        this.resetTransporter();
        cb(data.id);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async updateTransporter(transporter, rootState, cb) {
      try {
        await axios.put(
          `${address}/transporters/${transporter.id}`,
          transporter
        );
        notification["success"]({
          message: `Transporteur is succesvol bijgewerkt`
        });
        this.resetTransporter();
        cb();
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async getTransporterById(id) {
      const {data} = await axios.get(`${address}/transporters/${id}`);
      this.setCurrentTransporter(data);
    }
  }
};

export const customers = {
  state: {
    customers: [],
    loading: false
  },
  reducers: {
    setCustomersList(state, customers) {
      return {...state, customers};
    },
    addCustomer(state, customer) {
      return {...state, customers: [...state.customers.data, customer]};
    },
    deleteCustomer(state, id) {
      return {
        ...state,
        customers: {
          ...state.customers,
          data: state.customers.data.filter(item => item.id !== id)
        }
      };
    },
    toggleLoading(state, loading) {
      return {...state, loading};
    }
  },
  effects: {
    async getCustomers({results, page, sortField, sortOrder}) {
      try {
        this.toggleLoading(true);
        const {data} = await axios.get(
          `${address}/customers?results=${results}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}`
        );
        this.setCustomersList(data);
        this.toggleLoading(false);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async removeCustomer(id) {
      try {
        this.toggleLoading(true);
        await axios.delete(`${address}/customers/${id}`);
        this.deleteCustomer(id);
        notification["warning"]({
          message: `Klant met ID ${id} is succesvol verwijderd`
        });
      } catch (e) {
        notification["error"]({
          message: `${e.response.data}`
        });
      }
      this.toggleLoading(false);
    },
    async search(value) {
      try {
        this.toggleLoading(true);
        const {data} = await axios.get(
          `${address}/customers/search?query=${value}`
        );
        this.setCustomersList(data);
        this.toggleLoading(false);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    }
  }
};

export const transporters = {
  state: {
    transporters: [],
    loading: false
  },
  reducers: {
    setTransportersList(state, transporters) {
      return {...state, transporters};
    },
    addTransporter(state, transporter) {
      return {
        ...state,
        transporters: [...state.transporters.data, transporter]
      };
    },
    deleteTransporter(state, id) {
      return {
        ...state,
        transporters: {
          ...state.transporters,
          data: state.transporters.data.filter(item => item.id !== id)
        }
      };
    },
    toggleLoading(state, loading) {
      return {...state, loading};
    }
  },
  effects: {
    async getTransporters({results, page, sortField, sortOrder}) {
      try {
        this.toggleLoading(true);
        const {data} = await axios.get(
          `${address}/transporters?results=${results}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}`
        );
        this.setTransportersList(data);
        this.toggleLoading(false);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async removeTransporter(id) {
      try {
        this.toggleLoading(true);
        await axios.delete(`${address}/transporters/${id}`);
        this.deleteTransporter(id);
        notification["warning"]({
          message: `"Transporteur met ID ${id} succesvol verwijderd"`
        });
      } catch (e) {
        notification["error"]({
          message: `${e.response.data}`
        });
      }
      this.toggleLoading(false);
    },
    async search(value) {
      try {
        this.toggleLoading(true);
        const {data} = await axios.get(
          `${address}/transporters/search?query=${value}`
        );
        this.setTransportersList(data);
        this.toggleLoading(false);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    }
  }
};

export const shippingOrder = {
  state: {
    shippingOrder: {
      customer: {
        id: "",
        name: ""
      },
      transporter: {},
      loadingOrder: {
        address: {}
      },
      unloadingOrder: {
        address: {}
      },
      goods: []
    }
  },
  reducers: {
    onChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          [payload.target.id]: payload.target.value
        }
      };
    },
    onLoadingChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          loadingOrder: {
            ...state.shippingOrder.loadingOrder,
            [payload.target.id]: payload.target.value
          }
        }
      };
    },
    onUnLoadingChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          unloadingOrder: {
            ...state.shippingOrder.unloadingOrder,
            [payload.target.id]: payload.target.value
          }
        }
      };
    },
    onLoadingAddressChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          loadingOrder: {
            ...state.shippingOrder.loadingOrder,
            address: {
              ...state.shippingOrder.loadingOrder.address,
              [payload.target.id]: payload.target.value
            }
          }
        }
      };
    },
    onUnLoadingAddressChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          unloadingOrder: {
            ...state.shippingOrder.unloadingOrder,
            address: {
              ...state.shippingOrder.unloadingOrder.address,
              [payload.target.id]: payload.target.value
            }
          }
        }
      };
    },
    onChangeGood(state, {row, e}) {
      let newGoods = state.shippingOrder.goods;
      newGoods[row] = {...newGoods[row], [e.target.id]: e.target.value};
      return {
        ...state,
        shippingOrder: {...state.shippingOrder, goods: [...newGoods]}
      };
    },
    onChangeSelectionGood(state, {row, value}) {
      let newGoods = state.shippingOrder.goods;
      newGoods[row] = {...newGoods[row], type: value};
      return {
        ...state,
        shippingOrder: {...state.shippingOrder, goods: [...newGoods]}
      };
    },
    addEmptyGood(state) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          goods: [...state.shippingOrder.goods, {}]
        }
      };
    },
    onCheckboxChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          [payload.target.id]: payload.target.checked
        }
      };
    },
    onLoadingCheckboxChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          loadingOrder: {
            ...state.shippingOrder.loadingOrder,
            [payload.target.id]: payload.target.checked
          }
        }
      };
    },
    onUnloadingCheckboxChange(state, payload) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          unloadingOrder: {
            ...state.shippingOrder.unloadingOrder,
            [payload.target.id]: payload.target.checked
          }
        }
      };
    },
    onCustomerSelect(state, {id, name}) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          customer: {...state.shippingOrder.customer, id, name}
        }
      };
    },
    onTransporterSelect(state, {id, name}) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          transporter: {...state.shippingOrder.transporter, id, name}
        }
      };
    },
    onLoadingDateChange(state, value) {
      return {
        shippingOrder: {
          ...state.shippingOrder,
          loadingOrder: {
            ...state.shippingOrder.loadingOrder,
            date: moment(value, "DD-MM-YYYY")
          }
        }
      };
    },
    onUnloadingDateChange(state, value) {
      return {
        shippingOrder: {
          ...state.shippingOrder,
          unloadingOrder: {
            ...state.shippingOrder.unloadingOrder,
            date: moment(value, "DD-MM-YYYY")
          }
        }
      };
    },
    setCurrentShippingOrder(state, shippingOrder) {
      return {...state, shippingOrder};
    },
    deleteGoodFromShippingOrder(state, id) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          goods: state.shippingOrder.goods.filter(item => item.id !== id)
        }
      };
    },
    resetShippingOrder() {
      return {
        shippingOrder: {
          customer: {
            id: "",
            name: ""
          },
          transporter: {},
          loadingOrder: {
            address: {}
          },
          unloadingOrder: {
            address: {}
          },
          goods: []
        }
      };
    },
    setLoadingAddress(state, loadingAddress) {
      return {
        shippingOrder: {
          ...state.shippingOrder,
          loadingOrder: {
            ...state.shippingOrder.loadingOrder,
            address: loadingAddress
          }
        }
      };
    },
    setUnLoadingAddress(state, unloadingAddress) {
      return {
        shippingOrder: {
          ...state.shippingOrder,
          unloadingOrder: {
            ...state.shippingOrder.unloadingOrder,
            address: unloadingAddress
          }
        }
      };
    },
    onCustomerSearch(state, query) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          customer: {...state.customer, name: query}
        }
      };
    },
    onTransporterSearch(state, query) {
      return {
        ...state,
        shippingOrder: {
          ...state.shippingOrder,
          transporter: {...state.transporter, name: query}
        }
      };
    },
    onINCOSelect(state, payload) {
      return {
        ...state,
        shippingOrder: {...state.shippingOrder, INCO: payload}
      };
    }
  },
  effects: {
    async addShippingOrder(shippingOrder, rootState, cb) {
      try {
        const {data} = await axios.post(
          `${address}/shippingOrders`,
          shippingOrder
        );
        await dispatch({
          type: "shippingOrders/addShippingOrder",
          payload: data
        });
        cb();
        if (!shippingOrder.id) {
          notification["success"]({
            message: `Transportopdracht is succesvol toegevoegd`
          });
        } else {
          notification["success"]({
            message: `Transportopdracht is succesvol bijgewerkt`
          });
        }
        this.resetShippingOrder();
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async getCurrentShippingOrder(id) {
      const {data} = await axios.get(`${address}/shippingOrders/${id}`);
      //do transformation so that component gets it.
      const shippingOrder = {
        id: data.id,
        transporterReference: data.transporterReference,
        orderReferenceAdded: data.orderReferenceAdded,
        orderReferenceTransporter: data.orderReferenceTransporter,
        notes: data.notes,
        costs: data.costs,
        yields: data.yields,
        marge: data.marge,
        transporterRemark: data.transporterRemark,
        customer: data.customer,
        transporter: data.transporter,
        remarks: data.remarks,
        remarks_external: data.remarks_external,
        loadingOrder: {
          address: data.loadingAddress,
          loadingReference: data.loadingReference,
          loadingInstruction: data.loadingInstruction,
          date: data.loadingDate,
          loadingTime: data.loadingTime,
          loadingTimeFrom: data.loadingTimeFrom,
          loadingTimeTo: data.loadingTimeTo,
          loadingAnnouncement: data.loadingAnnouncement,
          loadingAnnouncementPhoneNumber: data.loadingAnnouncementPhoneNumber,
          loadingAnnouncementEmail: data.loadingAnnouncementEmail,
          loadingAnnouncementContact: data.loadingAnnouncementContact,
          loadingFix: data.loadingFix,
          loadingTailboard: data.loadingTailboard,
          loadingSwitchPallets: data.loadingSwitchPallets,
          loadingRemark: data.loadingRemark
        },
        unloadingOrder: {
          address: data.unloadingAddress,
          unloadingReference: data.unloadingReference,
          unloadingInstruction: data.unloadingInstruction,
          date: data.unloadingDate,
          unloadingTime: data.unloadingTime,
          unloadingTimeFrom: data.unloadingTimeFrom,
          unloadingTimeTo: data.unloadingTimeTo,
          unloadingAnnouncement: data.unloadingAnnouncement,
          unloadingAnnouncementPhoneNumber:
          data.unloadingAnnouncementPhoneNumber,
          unloadingAnnouncementEmail: data.unloadingAnnouncementEmail,
          unloadingAnnouncementContact: data.unloadingAnnouncementContact,
          unloadingFix: data.unloadingFix,
          unloadingTailboard: data.unloadingTailboard,
          unloadingSwitchPallets: data.unloadingSwitchPallets,
          unloadingRemark: data.unloadingRemark
        },
        goods: data.goods,
        INCO: data.INCO,
        trackAndTrace: data.trackAndTrace,
        externalReference: data.externalReference,
        officeId: data.officeId
      };
      this.setCurrentShippingOrder(shippingOrder);
    },
    async deleteGood(id) {
      try {
        await axios.delete(`${address}/shippingOrders/goods/${id}`);
        this.deleteGoodFromShippingOrder(id);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    }
  }
};

export const shippingOrders = {
  state: {
    shippingOrders: [],
    loading: false
  },
  reducers: {
    setShippingOrdersList(state, shippingOrders) {
      return {...state, shippingOrders};
    },
    addShippingOrder(state, shippingOrder) {
      return {
        ...state,
        shippingOrders: [...state.shippingOrders.data, shippingOrder]
      };
    },
    deleteShippingOrder(state, id) {
      return {
        ...state,
        shippingOrders: {
          ...state.shippingOrders,
          data: state.shippingOrders.data.filter(item => item.id !== id)
        }
      };
    },
    toggleLoading(state, loading) {
      return {...state, loading};
    },
    setAsPaid(state, id) {
      return {
        ...state,
        shippingOrders: {
          ...state.shippingOrders,
          data: state.shippingOrders.data.map(item => {
            if (item.id === id) {
              item.paid = !item.paid;
            }
            return item;
          })
        }
      };
    }
  },
  effects: {
    async getShippingOrders({results, page, sortField, sortOrder}) {
      try {
        this.toggleLoading(true);
        const {data} = await axios.get(
          `${address}/shippingOrders?results=${results}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}`
        );
        this.setShippingOrdersList(data);
        this.toggleLoading(false);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async removeShippingOrder(id) {
      try {
        await axios.delete(`${address}/shippingOrders/${id}`);
        this.deleteShippingOrder(id);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens!`
        });
      }
    },
    async search(value) {
      try {
        this.toggleLoading(true);
        const {data} = await axios.get(
          `${address}/shippingOrders/search?query=${value}`
        );
        this.setShippingOrdersList(data);
        this.toggleLoading(false);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async generatePDF({id, transporterReference}) {
      this.toggleLoading(true);
      try {
        const config = {responseType: "blob"};
        const response = await axios.get(
          `${address}/shippingOrders/${id}/pdf`,
          config
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `transportopdracht_${transporterReference}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      } finally {
        this.toggleLoading(false);
      }
    },
    async emailPDF({id, transporterReference}) {
      this.toggleLoading(true);
      try {
        const response = await axios.get(
          `${address}/shippingOrders/${id}/mailPDF`
        );
        if (response.data === "already sent") {
          notification["error"]({
            message: `Transportopdracht met ID ${transporterReference} is al verstuurd!`
          });
        } else if (response.data === "Sent email successfully!") {
          notification["success"]({
            message: `Transportopdracht met ID ${transporterReference} is succesvol verstuurd!`
          });
        }
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      } finally {
        this.toggleLoading(false);
      }
    },
    async togglePaid(id) {
      this.toggleLoading(true);
      try {
        await axios.put(`${address}/shippingOrders/paid/${id}`);
        this.setAsPaid(id);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      } finally {
        this.toggleLoading(false);
      }
    },
    async generateXML({id, transporterReference}) {
      this.toggleLoading(true);
      try {
        const config = {responseType: "blob"};
        const response = await axios.get(
          `${address}/shippingOrders/${id}/xml`,
          config
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `transportopdracht_${transporterReference}.xml`
        );
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      } finally {
        this.toggleLoading(false);
      }
    }
  }
};

export const finance = {
  state: {
    loading: false,
    startDate: moment(),
    endDate: moment(),
    shippingOrders: [],
    activeTab: "1",
    customers: [],
    transporters: []
  },
  reducers: {
    setLoading(state, loading) {
      return {...state, loading};
    },
    setDates(state, dates) {
      return {...state, startDate: dates[0], endDate: dates[1]};
    },
    setShippingOrders(state, shippingOrders) {
      return {...state, shippingOrders};
    },
    setActiveTab(state, tabKey) {
      return {...state, activeTab: tabKey};
    },
    setCustomers(state, customers) {
      return {...state, customers};
    },
    setSelectedCustomer(state, customer) {
      return {...state, selectedCustomer: customer};
    },
    setTransporters(state, transporters) {
      return {...state, transporters};
    },
    setSelectedTransporter(state, transporter) {
      return {...state, selectedTransporter: transporter};
    }
  },
  effects: {
    async onDateChange([startDate, endDate]) {
      this.setLoading(true);
      this.setDates([startDate, endDate]);
      const start = startDate.format("YYYY-MM-DD");
      const end = endDate.format("YYYY-MM-DD");
      const {data: shippingOrders} = await axios.get(
        `${address}/shippingOrders/byCreatedAtDate?startDate=${start}&endDate=${end}`
      );
      this.setShippingOrders(shippingOrders);
      this.setLoading(false);
    },
    async getCustomers() {
      const {data} = await axios.get(`${address}/customers/all`);
      this.setCustomers(data);
    },
    async getTransporters() {
      const {data} = await axios.get(`${address}/transporters/all`);
      this.setTransporters(data);
    },
    async downloadUnpaid() {
      try {
        const config = {responseType: "blob"};
        const response = await axios.get(
          `${address}/shippingOrders/unpaid`,
          config
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `unpaid.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data}`
        });
      }
    }
  }
};

export const overview = {
  state: {
    shippingOrders: [],
    selectedDate: moment(),
    activeTab: "1",
    transporters: [],
    selectedTransporter: undefined
  },
  reducers: {
    setSelectedDate(state, selectedDate) {
      return {...state, selectedDate};
    },
    setShippingOrders(state, shippingOrders) {
      return {...state, shippingOrders};
    },
    setActiveTab(state, tabKey) {
      return {...state, activeTab: tabKey};
    },
    setShippingOrderStatus(state, {id, status}) {
      const shippingOrders = state.shippingOrders.map(shippingOrder => {
        if (shippingOrder.id === id) {
          shippingOrder.status = status;
        }
        return shippingOrder;
      });
      return {
        ...state,
        shippingOrders: [...shippingOrders]
      };
    },
    setTransportersList(state, transporters) {
      return {
        ...state,
        transporters
      };
    },
    setSelectedTransporter(state, transporterId) {
      return {
        ...state,
        selectedTransporter: transporterId
      };
    }
  },
  effects: {
    async onDateChange(date) {
      this.setSelectedDate(date);
      const dateString = date.format("YYYY-MM-DD");
      const {data: shippingOrders} = await axios.get(
        `${address}/shippingOrders/byDate?date=${dateString}`
      );
      this.setShippingOrders(shippingOrders);
    },
    async changeStatus({id, status}) {
      try {
        await axios.put(`${address}/shippingOrders/updateStatus/${id}`, {
          status
        });
        this.setShippingOrderStatus({id, status});
      } catch (e) {
      }
    },
    async getTransporters() {
      try {
        const {data} = await axios.get(`${address}/transporters/all`);
        this.setTransportersList(data);
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    },
    async sendXML({id}) {
      try {
        await axios.post(`${address}/shippingOrders/${id}/api`);
        notification["success"]({
          message: `Opdracht is verstuurd`
        });
      } catch (e) {
        notification["error"]({
          message: `Er is iets fout gegaan, probeer het later nog eens! Error: ${e.response.data.error.message}`
        });
      }
    }
  }
};
