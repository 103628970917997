import React, {useState} from "react";
import {Button, Col, Input, Row, Table, Typography} from "antd";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "../../axios";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";

const {Title} = Typography;
const Search = Input.Search;

function CustomerList() {
  const columns = [
    {
      title: "Naam",
      dataIndex: "name",
      key: "name",
      render: (text, {id}) =>
        (<Link to={`/customers/${id}`}>{text}</Link>)
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Straat",
      dataIndex: "street",
      key: "street"
    },
    {
      title: "Postcode",
      dataIndex: "zipCode",
      key: "zipCode"
    },
    {
      title: "Stad",
      dataIndex: "city",
      key: "city"
    },
    {
      title: "Land",
      dataIndex: "country",
      key: "country"
    },
    {
      title: "Acties",
      dataIndex: "operation",
      key: "operation",
      render: (text, {id}) => (
        <Button
          type="danger"
          shape="circle"
          icon={<DeleteOutlined/>}
          onClick={() => deleteCustomer.mutate(id)}
        />
      )
    }
  ];
  const navigate = useNavigate();
  const [page, setPage] = useState({
    results: 10,
    page: 1,
    sortField: "id",
    sortOrder: "DESC"
  });
  const [searchTerm, setSearchTerm] = useState("");
  const onTableChange = (pagination, filters, sorter) => {
    const sortOrder = sorter.order === "ascending" ? "ASC" : "DESC";
    const newPage = {
      ...page,
      page: pagination.current,
      sortOrder
    };
    setPage(newPage);
  };
  const deleteCustomer = useMutation({
    mutationFn: (id) => {
      return axios.delete(`/customers/${id}`);
    }
  });
  const {isLoading, data} = useQuery({
    queryKey: ["customers", searchTerm, page, deleteCustomer],
    queryFn: () =>
      axios
        .get(`/customers?results=${page.results}&page=${page.page}&sortField=${page.sortField}&sortOrder=${page.sortOrder}&searchTerm=${searchTerm}`)
        .then((res) => res.data),
    keepPreviousData: true
  });

  return (
    <>
      <Title level={1}>Klanten</Title>
      <Row justify={"end"}>
        <Col>
          <Search
            placeholder="Zoek"
            size={"large"}
            onSearch={setSearchTerm}
            style={{width: 200, marginRight: "16px"}}
          />
        </Col>
        <Col>
          <Button icon={<PlusCircleOutlined/>} onClick={() => navigate("/customers/create")} size={"large"}>
            Voeg toe
          </Button>
        </Col>
      </Row>
      <Row style={{marginTop: "1rem"}}>
        <Col span={24}>
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={data?.data}
            loading={isLoading}
            pagination={{
              current: data?.page,
              total: data?.total
            }}
            onChange={onTableChange}
          />
        </Col>
      </Row>
    </>
  );
}

export default CustomerList;
