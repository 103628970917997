import React from "react";
import {Button, Card, Col, Form, Input, notification, Row, Typography} from "antd";
import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useIsAdmin} from "../../hooks/useIsAdmin";
import {SaveOutlined} from "@ant-design/icons";

const {Title} = Typography;
const FormItem = Form.Item;

const User = () => {
  const isAdmin = useIsAdmin()
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const createUser = useMutation({
    mutationFn: (transporter) => {
      return axios.post(`/users`, transporter);
    },
    onSuccess: () => {
      navigate("/users");
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });
  const onFinish = (form) => {
    createUser.mutate(form);
  };

  if (!isAdmin) {
    return <div>Geen toegang</div>
  }

  return (
    <div>
      <Title level={1}>Nieuwe gebruiker</Title>
      <Card>
        <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Title level={3}>Algemene informatie</Title>
              <FormItem label={"Gebruikersnaam"} name={"username"}>
                <Input/>
              </FormItem>
              <FormItem label={"Email"} name={"email"}>
                <Input/>
              </FormItem>
              <FormItem label={"Wachtwoord"} name={"password"}>
                <Input.Password/>
              </FormItem>
            </Col>
          </Row>
          <Row style={{marginTop: "1rem"}}>
            <Col span={24}>
              <Button type={"primary"} icon={<SaveOutlined/>} htmlType={"submit"} style={{float: "right"}}>
                Opslaan
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
}

export default User;