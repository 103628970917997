import React from "react";
import {AutoComplete, Button, Divider, Form, Input, notification, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios from "axios";

const Option = AutoComplete.Option;

export const CustomerSelect = (props) => {
  const [newCustomer, setNewCustomer] = React.useState("");
  const queryClient = useQueryClient();
  const customers = useQuery({
    queryKey: ["customers"],
    queryFn: () => axios.get(`/customers/all`)
      .then((res) => res.data)
  });
  const createCustomer = useMutation({
    mutationFn: (customer) => {
      return axios.post(`/customers`, {name: customer, email: `Niet beschikbaar voor ${customer}`});
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("customers");
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });

  return (
    <Form.Item label={"Klant"} name={"customerId"}>
      <Select
        showSearch
        loading={customers.isLoading}
        filterOption={(input, option) => {
          return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
        }}
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{margin: "8px 0"}}/>
            <Space style={{padding: "0 8px 4px"}}>
              <Input placeholder="Nieuwe klant naam" onChange={(e) => setNewCustomer(e.target.value)}/>
              <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => createCustomer.mutate(newCustomer)}>
                Opslaan
              </Button>
            </Space>
          </>
        )}>
        {customers.data?.map((customer) => (
          <Option key={customer.id} value={customer.id}>
            {customer.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};