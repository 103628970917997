import React, {Component} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {DatePicker, Row, Statistic, Table} from "antd";
import {toMoneyWithIcon} from "../../utils/formatMoney";
import moment from "moment";
import "./FinanceByDate.css";

const {RangePicker} = DatePicker;

const dateFormat = "DD/MM/YYYY";

class FinanceByDate extends Component {
  columns = [
    {
      title: "Dossiernummer",
      dataIndex: "transporterReference",
      key: "transporterReference",
      render: (text, {id}) => {
        return (
          <button className="link-button" onClick={() => this.props.navigate(`/orders/${id}`)}>
            {text}
          </button>
        );
      }
    },
    {
      title: "Laaddatum",
      dataIndex: "loadingDate",
      key: "loadingTime",
      render: this.renderDate,
    },
    {
      title: "Losdatum",
      dataIndex: "unloadingDate",
      key: "unloadingTime",
      render: this.renderDate,
    },
    {
      title: "Klant",
      dataIndex: ["customer", "name"],
      key: "customer.name",
      width: 150,
    },
    {
      title: "Laadadres",
      dataIndex: ["loadingAddress", "name"],
      key: "loadingAddress.name",
    },
    {
      title: "Laadplaats",
      dataIndex: ["loadingAddress", "city"],
      key: "loadingAddress.city",
    },
    {
      title: "Losadres",
      dataIndex: ["unloadingAddress", "name"],
      key: "unloadingAddress.name",
    },
    {
      title: "Losplaats",
      dataIndex: ["unloadingAddress", "city"],
      key: "unloadingAddress.city",
    },
    {
      title: "Email",
      dataIndex: "isEmailSent",
      key: "isEmailSent",
      render: this.renderEmailStatus,
    }
  ];

  componentDidMount() {
    this.props.onDateChange([this.props.startDate, this.props.endDate]);
  }

  calculateStats(shippingOrders) {
    return shippingOrders.reduce(
      ({count, costs, yields, marge}, currentValue) => {
        return {
          count: count + 1,
          costs: costs + currentValue.costs,
          yields: yields + currentValue.yields,
          marge: marge + (currentValue.yields - currentValue.costs)
        };
      },
      {count: 0, costs: 0, yields: 0, marge: 0}
    );
  }

  renderDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  renderEmailStatus(status) {
    if (status === 1) {
      return <CheckOutlined style={{color: "green"}}/>;
    } else {
      return <CloseOutlined style={{color: "red"}}/>;
    }
  }

  render() {
    const {count, costs, yields, marge} = this.calculateStats(
      this.props.shippingOrders
    );
    return (
      <div>
        <Row justify={"end"}>
          <Statistic
            title="Aantal"
            value={count}
            style={{marginRight: "16px"}}
          />
          <Statistic
            title="Kosten"
            value={toMoneyWithIcon(costs)}
            style={{marginRight: "16px"}}
          />
          <Statistic
            title="Opbrengsten"
            value={toMoneyWithIcon(yields)}
            style={{marginRight: "16px"}}
          />
          <Statistic
            title="Marge"
            value={toMoneyWithIcon(marge)}
            style={{marginRight: "16px"}}
          />
          <RangePicker
            size={"large"}
            onChange={this.props.onDateChange}
            value={[this.props.startDate, this.props.endDate]}
            format={dateFormat}
          />
        </Row>
        <Row style={{marginTop: "1rem"}}>
          <div style={{width: "100%"}}>
            <Table
              columns={this.columns}
              dataSource={this.props.shippingOrders}
              pagination={false}
              rowKey={"id"}
              loading={this.props.loading}
            />
          </div>
        </Row>
      </div>
    );
  }
}

export default FinanceByDate;
