import React, {Component} from "react";
import {CalendarOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Tabs} from "antd";
import FinanceByDate from "./FinanceByDate";
import FinanceByCustomer from "./FinanceByCustomer";
import {connect} from "react-redux";
import FinanceByTransporter from "./FinanceByTransporter";
import WithNavigate from "./WithNavigate";

const TabPane = Tabs.TabPane;

class FinanceContainer extends Component {

  render() {
    const operation = (
      <Button onClick={this.props.downloadUnpaid}>Onbetaald</Button>
    );
    return (
      <Tabs
        defaultActiveKey="1"
        activeKey={this.props.activeTab}
        onChange={this.props.setActiveTab}
        tabBarExtraContent={operation}
      >
        <TabPane
          key="1"
          tab={
            <span>
              <CalendarOutlined/>
              Dagoverzicht
            </span>
          }
        >
          <FinanceByDate
            shippingOrders={this.props.shippingOrders}
            onDateChange={this.props.onDateChange}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            navigate={this.props.navigate}
            getShippingOrdersByRange={this.props.getShippingOrdersByRange}
            loading={this.props.loading}
            data={this.props.data}
          />
        </TabPane>
        <TabPane
          key="2"
          tab={
            <span>
              <UserOutlined/>
              Klantoverzicht
            </span>
          }
        >
          <FinanceByCustomer
            customers={this.props.customers}
            getCustomers={this.props.getCustomers}
            selectedCustomer={this.props.selectedCustomer}
            setSelectedCustomer={this.props.setSelectedCustomer}
          />
        </TabPane>
        <TabPane
          key="3"
          tab={
            <span>
              <UserOutlined/>
              Transporteuroverzicht
            </span>
          }
        >
          <FinanceByTransporter
            transporters={this.props.transporters}
            getTransporters={this.props.getTransporters}
            selectedTransporter={this.props.selectedTransporter}
            setSelectedTransporter={this.props.setSelectedTransporter}
          />
        </TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = ({
                           finance: {
                             loading,
                             startDate,
                             endDate,
                             shippingOrders,
                             activeTab,
                             customers,
                             selectedCustomer,
                             transporters,
                             selectedTransporter
                           }
                         }) => {
  return {
    loading,
    startDate,
    endDate,
    shippingOrders,
    activeTab,
    customers,
    selectedCustomer,
    transporters,
    selectedTransporter
  };
};

const mapDispatch = ({
                       finance: {
                         onDateChange,
                         setActiveTab,
                         getCustomers,
                         setSelectedCustomer,
                         getTransporters,
                         setSelectedTransporter,
                         downloadUnpaid
                       }
                     }) => ({
  onDateChange,
  setActiveTab,
  getCustomers,
  setSelectedCustomer,
  getTransporters,
  setSelectedTransporter,
  downloadUnpaid
});

export default connect(mapStateToProps, mapDispatch)(WithNavigate(FinanceContainer));
