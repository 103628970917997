import axios from "axios";

const address = "https://api.a-tms.nl";
// const address = "http://localhost:3333";
axios.defaults.baseURL = address;
const token = sessionStorage.getItem("token");
if (token !== null) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default axios;