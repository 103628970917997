import React, {Component} from "react";
import {connect} from "react-redux";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {Button, Card, Col, Form, Input, Row} from "antd";
import {Navigate} from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {email: "", password: ""};
  }

  onChange = e => {
    this.setState({[e.target.id]: e.target.value});
  };

  onSubmit = e => {
    this.props.login(this.state.email, this.state.password);
  };

  render() {
    if (this.props.authenticated) {
      return <Navigate to="/"/>;
    }
    return (
      <div style={styles.body}>
        <Row justify={"center"} type={"flex"}>
          <Col xs={20} md={12} xl={6}>
            <Card style={{marginTop: "65px"}}>
              <div style={styles.cardImage}></div>
              <Form onFinish={this.onSubmit}>
                <Form.Item>
                  <Input
                    prefix={
                      <MailOutlined style={{color: "rgba(0,0,0,.25)"}}/>
                    }
                    type={"email"}
                    placeholder="Email"
                    id={"email"}
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    prefix={
                      <LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>
                    }
                    type="password"
                    placeholder="Password"
                    id={"password"}
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size={"large"}
                    type="primary"
                    style={{width: "100%"}}
                    htmlType={"submit"}
                  >
                    Log in!
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  body: {
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundImage:
      "linear-gradient(to right, rgb(83, 150, 209), rgb(91, 200, 171))"
  },
  cardImage: {
    background: "url(/img/Araido.svg) no-repeat",
    backgroundPosition: "center",
    height: 100,
    marginBottom: 20
  }
};

const mapStateToProps = ({auth: {authenticated}}) => {
  return {authenticated};
};

const mapDispatch = ({auth: {login}}) => ({
  login: (email, password) => login({email, password})
});

export default connect(mapStateToProps, mapDispatch)(Login);
