import React, {useState} from "react";
import {
  BarsOutlined,
  CarOutlined,
  EuroOutlined,
  HomeOutlined,
  LeftOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";

import {Button, Col, Layout, Menu, Row, Tooltip} from "antd";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import axios from "axios";
import CustomerList from "../customers/CustomerList";
import FinanceContainer from "../finance/FinanceContainer";
import Customer from "../customers/Customer";
import AddressList from "../address/AddressList";
import Address from "../address/Address";
import TransportersList from "../transporters/TransportersList";
import Transporter from "../transporters/Transporter";
import SearchView from "../search/Search";
import ShippingOrder from "../shippingOrders/ShippingOrder";
import ShippingOrdersList from "../shippingOrders/ShippingOrdersList";
import Home from "../home/Home";
import {UsersList} from "../users/Userslist";
import User from "../users/User";
import {useIsAdmin} from "../../hooks/useIsAdmin";
import {Profile} from "../profile/Profile";
import {OverviewContainer} from "../overview/OverviewContainer";

const {Header, Sider} = Layout;
const ButtonGroup = Button.Group;

// eslint-disable-next-line
import("./Main.css");

const Main = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const changeRoute = item => {
    navigate(`/${item.key}`);
  };

  const logout = () => {
    sessionStorage.clear();
    axios.defaults.headers.common["Authorization"] = undefined;
    navigate("/login");
  };

  const goBack = () => {
    navigate(-1)
  };

  return (
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
        {collapsed ? (
          <div className="logo-collapsed" onClick={() => navigate("/")}/>
        ) : (
          <div className="logo" onClick={() => navigate("/")}/>
        )}
        <Menu theme="dark" mode="inline" onClick={changeRoute} inlineIndent={16}>
          <Menu.Item key="search">
            <SearchOutlined/>
            <span>Zoeken</span>
          </Menu.Item>
          <Menu.Item key="customers">
            <UserOutlined/>
            <span>Klanten</span>
          </Menu.Item>
          <Menu.Item key="transporters">
            <CarOutlined/>
            <span>Transporteurs</span>
          </Menu.Item>
          <Menu.Item key="address">
            <HomeOutlined/>
            <span>Adressen</span>
          </Menu.Item>
          <Menu.Item key="orders">
            <ScheduleOutlined/>
            <span>Opdrachten</span>
          </Menu.Item>
          <Menu.Item key="overview">
            <BarsOutlined/>
            <span>Status</span>
          </Menu.Item>
          <Menu.Item key="finance">
            <EuroOutlined/>
            <span>Financieel</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{background: "#fff"}}>
          <ButtonGroup>
            <Button type="primary" onClick={goBack}>
              <LeftOutlined/>
              Terug
            </Button>
          </ButtonGroup>
          <Tooltip title="Logout">
            <LogoutOutlined
              style={{fontSize: "24px", float: "right", marginTop: "16px"}}
              onClick={logout}
            />
          </Tooltip>
          {isAdmin && (
            <Tooltip title="Usermanagement">
              <Link to={"/users"}>
                <UserOutlined style={{fontSize: "24px", float: "right", marginTop: "16px", marginRight: "16px"}}/>
              </Link>
            </Tooltip>
          )}

          {!isAdmin && (
            <Tooltip title="Profile">
              <Link to={"/profile"}>
                <UserOutlined style={{fontSize: "24px", float: "right", marginTop: "16px", marginRight: "16px"}}/>
              </Link>
            </Tooltip>
          )}
        </Header>
        <Row>
          <Col span={24}>
            <Layout style={{padding: "24px 24px"}}>
              <Routes>
                <Route path={"/"} exact element={<Home/>}></Route>
                <Route path={"/overview"} exact element={<OverviewContainer/>}/>
                <Route path={"/finance"} exact element={<FinanceContainer/>}/>
                <Route path={"/search"} exact element={<SearchView/>}/>
                <Route path={"/address"} exact element={<AddressList/>}/>
                <Route path={"/address/:id"} component={<Address/>}/>
                <Route path={"/orders"} exact element={<ShippingOrdersList/>}/>
                <Route path={"/orders/create"} exact element={<ShippingOrder/>}/>
                <Route path={"/orders/:id"} element={<ShippingOrder/>}/>
                <Route path={"/customers"} exact element={<CustomerList/>}/>
                <Route path={"/customers/create"} exact element={<Customer/>}/>
                <Route path={"/customers/:id"} element={<Customer/>}/>
                <Route path={"/transporters"} exact element={<TransportersList/>}/>
                <Route path={"/transporters/create"} exact element={<Transporter/>}/>
                <Route path={"/transporters/:id"} element={<Transporter/>}/>
                <Route path={"/profile"} element={<Profile/>}/>
                <Route path={"/users"} element={<UsersList/>}/>
                <Route path={"/users/create"} element={<User/>}/>
              </Routes>
            </Layout>
          </Col>
        </Row>
      </Layout>
    </Layout>);
};
export default Main;

