import React, {useRef, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import {Button, Input, Table} from "antd";
import moment from "moment";
import {useLocation, useNavigate} from "react-router";
import useAxios from "axios-hooks";
import {Link} from "react-router-dom";

const {Search} = Input;
const address = process.env.REACT_APP_API_URL;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchView() {
  let paramQuery = useQuery().get("query");
  const [query, setQuery] = useState(paramQuery);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const [{data: shippingOrders, loading}] = useAxios(`${address}/shippingOrders/search?query=${query}&pagesize=1000`);

  const defaultColumns = [
    {
      title: "Dossiernummer",
      dataIndex: "transporterReference",
      render: (text, {id}) => {
        return <Link to={`/orders/${id}`}>{text}</Link>;
      },
      width: 150
    },
    {
      title: "Klant",
      dataIndex: ["customer", "name"],
      width: 150
    },
    {
      title: "Transporteur",
      dataIndex: ["transporter", "name"],
      onFilter: (value, record) => {
        return record.transporterId === value;
      }
    },
    {
      title: "Laden",
      dataIndex: "laden",
      children: [
        {
          title: "Datum",
          dataIndex: "datum-laden",
          render: (text, record) => {
            return `${moment(record.loadingDate).format("DD-MM-YYYY")}`;
          }
        },
        {
          title: "Land-postcode",
          dataIndex: "land-postcode-laden",
          render: (text, record) => {
            return `${record.loadingAddress.country}-${record.loadingAddress.zipCode}`;
          }
        },
        {
          title: "Plaats",
          dataIndex: "plaats-laden",
          filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
            return (
              <div style={{padding: 8}}>
                <Input
                  ref={searchInputRef}
                  placeholder={`Search`}
                  value={selectedKeys[0]}
                  onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{width: 188, marginBottom: 8, display: "block"}}
                />
                <Button onClick={clearFilters} size="small" style={{width: 90}}>
                  Reset
                </Button>
              </div>
            );
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => searchInputRef.current.select());
            }
          },
          filterIcon: filtered => (
            <SearchOutlined
              style={{
                color: filtered ? "#1890ff" : undefined,
                fontSize: 18,
                fontWeight: "bold"
              }}
            />
          ),
          onFilter: (value, record) => {
            return record.loadingAddress.city === `${value}`;
          },
          render: (text, record) => {
            return `${record.loadingAddress.city}`;
          }
        }
      ]
    },
    {
      title: "Lossen",
      dataIndex: "lossen",
      children: [
        {
          title: "Datum",
          dataIndex: "datum-lossen",
          render: (text, record) => {
            return `${moment(record.unloadingDate).format("DD-MM-YYYY")}`;
          }
        },
        {
          title: "Land-postcode",
          dataIndex: "land-postcode-lossen",
          render: (text, record) => {
            return `${record.unloadingAddress.country}-${record.unloadingAddress.zipCode}`;
          }
        },
        {
          title: "Plaats",
          dataIndex: "plaats-lossen",
          filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
            return (
              <div style={{padding: 8}}>
                <Input
                  ref={searchInputRef}
                  placeholder={`Search`}
                  value={selectedKeys[0]}
                  onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={confirm}
                  style={{width: 188, marginBottom: 8, display: "block"}}
                />
                <Button onClick={clearFilters} size="small" style={{width: 90}}>
                  Reset
                </Button>
              </div>
            );
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => searchInputRef.current.select());
            }
          },
          filterIcon: filtered => (
            <SearchOutlined
              style={{
                color: filtered ? "#1890ff" : undefined,
                fontSize: 18,
                fontWeight: "bold"
              }}
            />
          ),
          onFilter: (value, record) => {
            return record.unloadingAddress.city === `${value}`;
          },
          render: (text, record) => {
            return `${record.unloadingAddress.city}`;
          }
        }
      ]
    },
    {
      title: "Opbrengsten",
      dataIndex: "yields"
    }
  ];

  const removeDuplicates = (array, key) => {
    return array?.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[key]).indexOf(obj[key]) === pos;
    });
  };

  let transporterFilter = shippingOrders?.data.map(order => {
    return {text: order.transporter?.name, value: order.transporter?.id};
  });

  transporterFilter = removeDuplicates(transporterFilter, "text");
  const columns = [...defaultColumns];
  columns[2].filters = transporterFilter;

  return (
    <div>
      <Search
        placeholder="Zoek op elementen"
        enterButton="Zoek"
        size="large"
        defaultValue={query}
        onSearch={query => {
          setQuery(query);
          navigate({search: `?query=${query}`});
        }}
        loading={loading}
      />
      <Table
        columns={columns}
        dataSource={shippingOrders?.data}
        rowKey={"id"}
        bordered
        style={{marginTop: 8}}
        loading={loading}
      />
    </div>
  );
}

export default SearchView;
