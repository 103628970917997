import React, {useEffect} from "react";
import {Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, notification, Row, Typography} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";
import {OfficeSelect} from "./components/OfficeSelect";
import {CustomerSelect} from "./components/CustomerSelect";
import {IncoSelect} from "./components/IncoSelect";
import useCostsAndYields from "./hooks/useCostsAndYields";
import {TransporterReferenceInput} from "./components/TransporterReferenceInput";
import {TransporterSelect} from "./components/TransporterSelect";
import {LoadAddressSelect} from "./components/LoadAddressSelect";
import {UnloadAddressSelect} from "./components/UnloadAddressSelect";
import {LoadingAnnouncement} from "./components/LoadingAnnouncement";
import {useNavigate, useParams} from "react-router-dom";
import {UnloadingAnnouncement} from "./components/UnloadingAnnouncement";
import moment from "moment";
import Goods from "./components/Goods";

const {Title} = Typography;
const FormItem = Form.Item;
const {TextArea} = Input;

const ShippingOrder = (props) => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [form] = Form.useForm();
  const shippingOrder = useQuery({
    queryKey: ["customer", id],
    queryFn: () => axios.get(`/shippingOrders/${id}`)
      .then((res) => res.data),
    enabled: !!id
  });
  useEffect(() => {
    if (!shippingOrder.isLoading && shippingOrder.data) {
      const formattedData = {
        ...shippingOrder.data,
        loadingDate: moment(shippingOrder.data.loadingDate),
        unloadingDate: moment(shippingOrder.data.unloadingDate)
      };
      form.setFieldsValue(formattedData);
    }
  }, [shippingOrder.isLoading, shippingOrder.data, form]);

  const initialCosts = shippingOrder.data?.costs || 0;
  const initialYields = shippingOrder.data?.yields || 0;
  const {margin, handleCostsChange, handleYieldsChange} = useCostsAndYields(initialCosts, initialYields);
  const createOrUpdateShippingOrder = useMutation({
    mutationFn: (shippingOrder) => {
      return axios.post(`/shippingorders/new`, shippingOrder);
    },
    onSuccess: () => {
      const args = {
        message: "Transportopdracht opgeslagen",
        description: "De transportopdracht is succesvol opgeslagen",
        duration: 15
      };
      notification.success(args);
      navigate("/orders")
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });

  return (
    <div>
      <Row>
        <Col span={21}>
          <Title level={1}>Transportopdracht</Title>
        </Col>
        <Col span={2}>
          {/*<Button onClick={() => this.createShipment(shippingOrder.id)}>DHL</Button>*/}
        </Col>
      </Row>
      <Card>
        <Row style={{marginTop: 16}}>
          <Col span={24}>
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}}
                  onFinish={(e) => createOrUpdateShippingOrder.mutate(e)}>
              <FormItem hidden label={"id"} name={"id"}>
                <Input/>
              </FormItem>
              <Row>
                <Col xs={24} lg={12}>
                  <OfficeSelect/>
                  <CustomerSelect/>
                  <FormItem label={"Kosten"} name={"costs"}>
                    <Input addonBefore="€" onChange={handleCostsChange}/>
                  </FormItem>
                  <FormItem label={"Opbrengsten"} name={"yields"}>
                    <Input addonBefore="€" onChange={handleYieldsChange}/>
                  </FormItem>
                  <FormItem label={"Marge"}>
                    <Input disabled addonBefore="€" value={margin}/>
                  </FormItem>
                  <IncoSelect/>
                </Col>
                <Col xs={24} lg={12}>
                  <TransporterSelect/>
                  <TransporterReferenceInput form={form}/>
                  <FormItem label={"Externe referentie"} name={"externalReference"}>
                    <Input disabled/>
                  </FormItem>
                  <FormItem label={"Factuurreferentie"} name={"transporterRemark"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Factuur Added"} name={"orderReferenceAdded"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Factuur vervoerder"} name={"orderReferenceTransporter"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Notities"} name={"notes"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Track & Trace"} name={"trackAndTrace"}>
                    <Input/>
                  </FormItem>
                </Col>
              </Row>
              <Divider/>
              <Title level={3}>Laden/lossen</Title>
              <Row>
                <Col xs={24} lg={12}>
                  <LoadAddressSelect form={form}/>
                  <LoadingAnnouncement/>
                  <FormItem label={"Laadreferentie"} name={"loadingReference"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Laadinstructie"} name={"loadingInstruction"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Datum"} name={"loadingDate"}>
                    <DatePicker format="DD-MM-YYYY" placeholder="Selecteer datum" style={{width: "100%"}}/>
                  </FormItem>
                  <FormItem label={"Tijden van"} name={"loadingTimeFrom"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Tijden tot"} name={"loadingTimeTo"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Fix laden"} name={"loadingFix"} valuePropName="checked">
                    <Checkbox/>
                  </FormItem>
                  <FormItem label={"Laadklep"} name={"loadingTailboard"} valuePropName="checked">
                    <Checkbox/>
                  </FormItem>
                  <FormItem label={"Pallets ruilen"} name={"loadingSwitchPallets"} valuePropName="checked">
                    <Checkbox/>
                  </FormItem>
                  <FormItem wrapperCol={{xs: {span: 16}, sm: {span: 16}}} label={"Opmerkingen intern"}
                            name={"remarks"}>
                    <TextArea rows={4}/>
                  </FormItem>
                </Col>
                <Col xs={24} lg={12}>
                  <UnloadAddressSelect form={form}/>
                  <UnloadingAnnouncement/>
                  <FormItem label={"Losreferentie"} name={"unloadingReference"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Losinstructie"} name={"unloadingInstruction"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Datum"} name={"unloadingDate"}>
                    <DatePicker format="DD-MM-YYYY" placeholder="Selecteer datum" style={{width: "100%"}}/>
                  </FormItem>
                  <FormItem label={"Tijden van/tot"} name={"unloadingTimeFrom"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Tijden van/tot"} name={"unloadingTimeTo"}>
                    <Input/>
                  </FormItem>
                  <FormItem label={"Fix lossen"} name={"unloadingFix"} valuePropName="checked">
                    <Checkbox/>
                  </FormItem>
                  <FormItem label={"Laadklep"} name={"unloadingTailboard"} valuePropName="checked">
                    <Checkbox/>
                  </FormItem>
                  <FormItem label={"Pallets ruilen"} name={"unloadingSwitchPallets"} valuePropName="checked">
                    <Checkbox name={"unloadingSwitchPallets"}/>
                  </FormItem>
                  <FormItem wrapperCol={{xs: {span: 16}, sm: {span: 16}}} label={"Opmerkingen extern"}
                            name={"remarks_external"}>
                    <TextArea rows={4}/>
                  </FormItem>
                </Col>
              </Row>
              <Divider/>
              <Title level={3}>Goederen</Title>
              <Row>
                <Col span={24}>
                  <Goods form={form}/>
                </Col>
              </Row>
              <Divider/>
              <Row align={"center"} justify={"end"} style={{marginTop: "1rem"}}>
                <Col span={3}>
                  <Button style={{float: "right"}} type={"primary"} icon={<SaveOutlined/>} htmlType={"submit"}>
                    Opslaan
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ShippingOrder;
