import {formatMoney} from "accounting-js";

export function toMoney(amount) {
  if (amount) {
    return formatMoney(amount, {
      symbol: "",
      precision: 2,
      thousand: ".",
      decimal: ","
    });
  }
  return amount;
}

export function toMoneyWithIcon(amount) {
  if (amount) {
    return formatMoney(amount, {
      symbol: "€",
      precision: 2,
      thousand: ".",
      decimal: ","
    });
  }
  return amount;
}
