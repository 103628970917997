import React, {Component} from "react";
import {Col, DatePicker, Row, Select, Table, Typography} from "antd";
import moment from "moment";
import {Link} from "react-router-dom";
import {translateStatusWithTag} from "../../utils/statusHelper";
import Popup from "../custom/Popup";

const dateFormat = "DD/MM/YYYY";
const {Title} = Typography;
const {Option} = Select;

const loadColumns = [
  {
    title: "Dossiernummer",
    dataIndex: "transporterReference",
    render: (text, shippingOrder) => {
      return <Link to={`/orders/${shippingOrder.id}`}> {text}</Link>;
    }
  },
  {
    title: "Laaddatum",
    dataIndex: "loadingDate",
    render: (text, shippingOrder) => {
      return moment(shippingOrder.loadingDate).format("DD-MM-YYYY");
    }
  },
  {
    title: "Losdatum",
    dataIndex: "unloadingDate",
    render: (text, shippingOrder) => {
      return moment(shippingOrder.unloadingDate).format("DD-MM-YYYY");
    }
  },
  {
    title: "Laden",
    children: [
      {
        title: "Bedrijf",
        dataIndex: ["loadingAddress", "name"]
      },
      {
        title: "Stad",
        dataIndex: ["loadingAddress", "city"]
      },
      {
        title: "Land",
        dataIndex: ["loadingAddress", "country"]
      },
      {
        title: "Tijd",
        dataIndex: ["loadingTimeTo"]
      }
    ]
  },
  {
    title: "Lossen",
    children: [
      {
        title: "Bedrijf",
        dataIndex: ["unloadingAddress", "name"]
      },
      {
        title: "Stad",
        dataIndex: ["unloadingAddress", "city"]
      },
      {
        title: "Land",
        dataIndex: ["unloadingAddress", "country"]
      },
      {
        title: "Tijd",
        dataIndex: ["unloadingTimeTo"]
      }
    ]
  },
  {
    title: "Transporteur",
    dataIndex: ["transporter", "name"]
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => translateStatusWithTag(status)
  }
];

const unloadColumns = [
  {
    title: "Dossiernummer",
    dataIndex: "transporterReference",
    render: (text, shippingOrder) => {
      return <Link to={`/orders/${shippingOrder.id}`}> {text}</Link>;
    }
  },
  {
    title: "Laaddatum",
    dataIndex: "loadingDate",
    render: (text, shippingOrder) => {
      return moment(shippingOrder.loadingDate).format("DD-MM-YYYY");
    }
  },
  {
    title: "Losdatum",
    dataIndex: "unloadingDate",
    render: (text, shippingOrder) => {
      return moment(shippingOrder.unloadingDate).format("DD-MM-YYYY");
    }
  },
  {
    title: "Transporteur",
    dataIndex: ["transporter", "name"]
  },
  {
    title: "Losplaats",
    dataIndex: ["unloadingAddress", "city"]
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => translateStatusWithTag(status)
  }
];

class OrderOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: {
        visible: false,
        x: 0,
        y: 0
      }
    };
  }

  onPopupClick = e => {
    if (e.status === "EDI") {
      this.props.sendXML(e);
    } else {
      console.log(e.status);
      this.props.updateStatus({id: e.id, status: e.status});
    }
  };

  splitShippingOrdersByDate(shippingOrders) {
    return shippingOrders
      ?.filter(item =>
        this.props.selectedTransporter
          ? item.transporterId === this.props.selectedTransporter
          : true
      )
      .sort((a, b) => b.transporter?.name.localeCompare(a.transporter?.name))
      .reduce(
        ({load, unload}, current) => {
          const loadingDate = moment(current.loadingDate).format("DD-MM-YYYY");
          const actualDate = this.props.selectedDate.format("DD-MM-YYYY");
          if (loadingDate === actualDate) {
            return {
              load: [...load, current],
              unload: [...unload]
            };
          } else {
            return {
              load: [...load],
              unload: [...unload, current]
            };
          }
        },
        {load: [], unload: []}
      );
  }

  onRow = record => ({
    onContextMenu: event => {
      event.preventDefault();
      if (!this.state.visible) {
        const that = this;
        document.addEventListener(`click`, function onClickOutside() {
          that.setState({popup: {visible: false}});
          document.removeEventListener(`click`, onClickOutside);
        });
      }
      this.setState({
        popup: {
          record,
          visible: true,
          x: event.clientX,
          y: event.clientY,
          onClick: this.onPopupClick
        }
      });
    }
  });

  renderOptions({load, unload}) {
    const {transporters, type} = this.props;
    return transporters
      .filter(({id}) =>
        type === "load"
          ? load.find(shippingOrder => shippingOrder.transporterId === id)
          : unload.find(shippingOrder => shippingOrder.transporterId === id)
      )
      .map(transporter => {
        return (
          <Option key={transporter.id} value={transporter.id}>
            {transporter.name}
          </Option>
        );
      });
  }

  render() {
    const {load, unload} = this.splitShippingOrdersByDate(this.props.shippingOrders);
    const {type} = this.props;
    return (
      <div>
        <Row justify={"end"}>
          <Select
            showSearch
            allowClear
            value={this.props.selectedTransporter}
            size={"large"}
            style={{width: 300, marginRight: "16px"}}
            placeholder="Filter op transporteur"
            optionFilterProp="children"
            onChange={this.props.setSelectedTransporter}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.renderOptions({load, unload})}
          </Select>
          <DatePicker
            onChange={this.props.onDateChange}
            size={"large"}
            value={this.props.selectedDate}
            format={dateFormat}
          />
        </Row>
        <Row gutter={24} style={{marginTop: 16}}>
          {type === "load" ? (
            <Col span={24}>
              <Table
                rowKey={record => record.id}
                loading={this.props.loading}
                columns={loadColumns}
                dataSource={load}
                bordered
                title={() => <Title level={4}>Laden</Title>}
                pagination={false}
                onRow={this.onRow}
              />
            </Col>
          ) : (
            <Col span={24}>
              <Table
                rowKey={record => record.id}
                columns={unloadColumns}
                dataSource={unload}
                bordered
                title={() => <Title level={4}>Lossen</Title>}
                pagination={false}
                onRow={this.onRow}
              />
            </Col>
          )}
        </Row>
        <Popup {...this.state.popup} />
      </div>
    );
  }
}

export default OrderOverview;
