import React, {useState} from "react";
import {Col, Input, Row, Table} from "antd";
import useAxios from "axios-hooks";
import {Link} from "react-router-dom";

const address = process.env.REACT_APP_API_URL;
const {Search} = Input;

const columns = [
  {
    title: "Locatienaam",
    dataIndex: "name",
    key: "name",
    render: text => <Link to={`/address/${text}`}>{text}</Link>
  },
  {
    title: "Straat",
    dataIndex: "street",
    key: "street"
  },
  {
    title: "Postcode",
    dataIndex: "zipCode",
    key: "zipCode"
  }
];

function AddressList() {
  const [{data, loading}] = useAxios(`${address}/address`);
  const [search, setSearch] = useState(null);

  const tableData =
    search === null
      ? data
      : data.filter(data => {
        return data.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      });

  return (
    <div>
      <Row justify={"end"}>
        <Search
          placeholder="Zoek"
          size={"large"}
          style={{width: "200px", marginRight: "16px"}}
          onSearch={setSearch}
        />
      </Row>
      <Row style={{marginTop: "1rem"}}>
        <Col span={24}>
          <Table columns={columns} dataSource={tableData} loading={loading}/>
        </Col>
      </Row>
    </div>
  );
}

export default AddressList;
