import React from "react";
import {AutoComplete, Button, Divider, Form, Input, notification, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import axios from "axios";

const Option = AutoComplete.Option;

export const TransporterSelect = (props) => {
  const [newTransporter, setNewTransporter] = React.useState("");
  const queryClient = useQueryClient();
  const transporters = useQuery({
    queryKey: ["transporters"],
    queryFn: () => axios.get(`/transporters/all`)
      .then((res) => res.data)
  });
  const createTransporter = useMutation({
    mutationFn: (transporter) => {
      return axios.post(`/transporters`, {name: transporter, email: `Niet beschikbaar voor ${transporter}`});
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("transporters");
    },
    onError: (error, variables, context) => {
      const args = {
        message: "Er ging iets fout",
        description: error.response.data.error.message,
        duration: 15
      };
      notification.error(args);
    }
  });

  return (
    <Form.Item label={"Transporteur"} name={"transporterId"}>
      <Select
        showSearch
        loading={transporters.isLoading}
        filterOption={(input, option) => {
          return (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
        }}
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{margin: "8px 0"}}/>
            <Space style={{padding: "0 8px 4px"}}>
              <Input placeholder="Nieuwe transporteur naam" onChange={(e) => setNewTransporter(e.target.value)}/>
              <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => createTransporter.mutate(newTransporter)}>
                Opslaan
              </Button>
            </Space>
          </>
        )}>
        {transporters.data?.map((transporter) => (
          <Option key={transporter.id} value={transporter.id}>
            {transporter.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};